export const FOOTER_HTML = `
<div class="footer">
  <div class="container">
    <div class="aboutus">
      <div class="section">
        <h2>About Us</h2>
        <p>We're <a href="https://www.hey.gg">Hey, Good Game</a>, a tiny game studio that builds and buys games, for
          good. We believe brainy games can impact your life in a positive and healthy way, and we're dedicated to
          creating a premium game experience across platforms.</p>
        <p>Learn more <a href="https://www.hey.gg/about">about us</a>, and please reach out if you have a game to sell.
          We have a streamlined process that treats game caretakers fairly.</p>
      </div>
      <p class="copyright">© 2024-2024 Hey Good Game, Inc.</p>
    </div>
    <div class="games">
      <h2>Hey, Good Games</h2>
      <ul>
        <li class="mathler"><a href="https://www.mathler.com">Mathler</a></li>
        <li class="kakuroconquest"><a href="https://www.kakuroconquest.com">Kakuro Conquest</a></li>
        <li class="crosswordle"><a href="https://crosswordle.com">Crosswordle</a></li>
        <li class="squeezy"><a href="https://imsqueezy.com">Squeezy</a></li>
        <li class="sumplete"><a href="https://sumplete.com">Sumplete</a></li>
        <li class="sudokuconquest"><a href="https://www.sudokuconquest.com">Sudoku Conquest</a></li>
        <li class="hitoriconquest"><a href="https://hitoriconquest.com">Hitori Conquest</a></li>
        <li class="wordga"><a href="https://wordga.com">WordGa</a></li>
      </ul>
    </div>
    <div class="resources">
      <h2>Resources</h2>
      <ul>
        <li><a href="https://www.hey.gg/podcast">Podcast</a></li>
        <li><a href="https://www.youtube.com/@heygoodgames">YouTube</a></li>
        <li><a href="https://www.hey.gg/newsletter">Newsletter</a></li>
        <!-- Add any custom link after this line -->
        <li><a href="https://www.hey.gg/privacy">Privacy Policy</a></li>
      </ul>
    </div>
  </div>
</div>
`;
