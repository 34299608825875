import { Streak } from '../hooks/useStreak';

export default function getCurrentStreak(streaks: Streak[]): number {
  const lastStreak = streaks?.length && streaks[streaks.length - 1];

  if (!lastStreak) return 0;

  const today = new Date();

  const d1 = new Date(lastStreak.updated_at);

  const timeDifference = Math.abs(d1.getTime() - today.getTime());

  const dayDifference = timeDifference / (1000 * 60 * 60 * 24);

  if (dayDifference >= 2) {
    return 0;
  } else {
    return lastStreak.current_play_streak;
  }
}
