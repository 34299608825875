// Import any other modules or utilities you need
// import { someUtility } from './utilities';
import Auth from './auth';
import Streaks from './streaks';
import { handleAuthAndSetupUI, toggleSidebar } from './dom-manipulation';

interface HeyGGConfig {
  apiBaseUrl: string;
}

const defaultConfig: HeyGGConfig = {
  apiBaseUrl: 'https://play.hey.gg',
};

const config = { ...defaultConfig };

const setConfig = (newConfig: Partial<HeyGGConfig>) => {
  window.HeyGoodGame.config = { ...window.HeyGoodGame.config, ...newConfig };
};

// Export everything you want to make available globally
export { Auth, Streaks, config, setConfig, toggleSidebar, handleAuthAndSetupUI };

declare global {
  interface Window {
    HeyGoodGame: {
      Auth: typeof Auth;
      Streaks: typeof Streaks;
      config: HeyGGConfig;
      setConfig: (newConfig: Partial<HeyGGConfig>) => void;
      toggleSidebar: () => void;
      handleAuthAndSetupUI: (
        gameTitle: string,
        streakLogs: string[][],
        svgLogo: SVGAElement,
      ) => void;
    };
  }
}

window.HeyGoodGame = {
  Auth,
  Streaks,
  config,
  setConfig,
  toggleSidebar,
  handleAuthAndSetupUI,
};
