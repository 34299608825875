// Import any other modules or utilities you need
// import { someUtility } from './utilities';
import Auth from './auth';
import Streaks from './streaks';
import Stats from './stats';

import {
  handleAuthAndSetupUI,
  HandleAuthAndSetupUIProps,
  toggleSidebar,
} from './dom-manipulation';

interface HeyGGConfig {
  apiBaseUrl: string;
}

const defaultConfig: HeyGGConfig = {
  apiBaseUrl: 'https://play.hey.gg',
};

const config = { ...defaultConfig };

const setConfig = (newConfig: Partial<HeyGGConfig>) => {
  window.HeyGoodGame.config = { ...window.HeyGoodGame.config, ...newConfig };
};

// Export everything you want to make available globally
export {
  Auth,
  Streaks,
  Stats,
  config,
  setConfig,
  toggleSidebar,
  handleAuthAndSetupUI,
};

declare global {
  interface Window {
    HeyGoodGame: {
      Auth: typeof Auth;
      Streaks: typeof Streaks;
      Stats: typeof Stats;
      config: HeyGGConfig;
      setConfig: (newConfig: Partial<HeyGGConfig>) => void;
      toggleSidebar: () => void;
      handleAuthAndSetupUI: (props: HandleAuthAndSetupUIProps) => Promise<void>;
    };
  }
}

window.HeyGoodGame = {
  Auth,
  Streaks,
  Stats,
  config,
  setConfig,
  toggleSidebar,
  handleAuthAndSetupUI,
};
