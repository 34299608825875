export default function getLast7Days(): string[] {
  const dates = [];
  const today = new Date();

  for (let i = 0; i < 7; i++) {
    const date = new Date();
    date.setDate(today.getDate() - i);
    dates.unshift(date.toISOString().split('T')[0]);
  }
  return dates;
}
