const Stats = {
  sendStats: async function (
    gameKey: string,
    gameDate: string,
    level: string,
    gameResult: string,
    stats: any,
  ): Promise<void> {
    const apiBaseUrl = window.HeyGoodGame.config.apiBaseUrl;
    const accessToken = localStorage.getItem('heygg_jwt');
    const anonymousId = localStorage.getItem('heygg_anonymous_id');

    const headers: { 'Content-Type': string; Authorization?: string } = {
      'Content-Type': 'application/json',
    };

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    const body: {
      game_key: string;
      game_date: string;
      game_id: string;
      game_result: string;
      stats: any;
      anonymous_id?: string;
    } = {
      game_key: gameKey,
      game_date: gameDate,
      game_id: `${level}:${gameDate}`,
      game_result: gameResult,
      stats: { level, ...stats },
    };

    if (!accessToken && anonymousId) {
      body.anonymous_id = anonymousId;
    }

    try {
      const response = await fetch(`${apiBaseUrl}/api/game-stats`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error('Failed to send stats');
      }

      await response.json();
    } catch (err) {
      throw err;
    }
  },
  sendHistoryStats: async function (
    gameKey: string,
    level: string,
    stats: any,
  ): Promise<void> {
    const apiBaseUrl = window.HeyGoodGame.config.apiBaseUrl;
    const accessToken = localStorage.getItem('heygg_jwt');
    const anonymousId = localStorage.getItem('heygg_anonymous_id');

    const headers: { 'Content-Type': string; Authorization?: string } = {
      'Content-Type': 'application/json',
    };

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    const body: {
      game_key: string;
      level: string;
      stats: any;
      anonymous_id?: string;
    } = {
      game_key: gameKey,
      level: level,
      stats: stats,
    };

    if (!accessToken && anonymousId) {
      body.anonymous_id = anonymousId;
    }

    try {
      const response = await fetch(`${apiBaseUrl}/api/historical-stats`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to send stats');
      }

      await response.json();
    } catch (err) {
      throw err;
    }
  },
};

export default Stats;
