export const NAVBAR_HTML = `
<ul class="navbar">
 <div class="user-profile-dropdown">
  <p id="current-streak"></p>
  <p id="username"></p>
 </div>
 <div class="dropdown-content">
  <p>PAST 7 DAYS</p>
  <div id="days" class="days"></div>
  <a href="#" id="accountStatsBtn">
   <svg width="40" height="43" viewBox="0 0 40 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_13712_468)">
     <path d="M10 21.9082H14V32.9082H10V21.9082Z" fill="#FCFAFA" />
     <path d="M16 10.9082H25V14.9082H21V32.9082H16V10.9082Z" fill="var(--heygg-primary-color)" />
     <path d="M23 16.9082H30V32.9082H23V16.9082Z" fill="white" />
    </g>
    <defs>
     <clipPath id="clip0_13712_468">
      <rect width="24" height="24" fill="white" transform="translate(8 9.9082)" />
     </clipPath>
    </defs>
   </svg>
   <span>Stats</span>
  </a>
  <a href="#how-to-play" id="faqBtn">
   <svg width="40" height="43" viewBox="0 0 40 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
     d="M20 33.9082C26.6274 33.9082 32 28.5356 32 21.9082C32 15.2808 26.6274 9.9082 20 9.9082C13.3726 9.9082 8 15.2808 8 21.9082C8 28.5356 13.3726 33.9082 20 33.9082Z"
     fill="var(--heygg-primary-color)" />
    <path
     d="M16 18.285C16.0001 17.6761 16.1952 17.0786 16.5645 16.5557C16.9339 16.0327 17.4638 15.6038 18.0982 15.3143C18.7327 15.0248 19.448 14.8856 20.1685 14.9112C20.8889 14.9368 21.5878 15.1265 22.1911 15.46C22.7945 15.7935 23.2798 16.2586 23.5957 16.8059C23.9116 17.3532 24.0464 17.9625 23.9859 18.5692C23.9253 19.1759 23.6716 19.7575 23.2516 20.2525C22.8316 20.7474 22.2609 21.1373 21.6 21.3809C21.1245 21.5561 20.7198 21.8447 20.4356 22.2112C20.1514 22.5777 20 23.0063 20 23.4444V23.9082"
     stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx="20" cy="27.9082" r="1" fill="black" />
   </svg>
   <span>How to Play</span>
  </a>
 </div>
</ul>
`;
