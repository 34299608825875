    // Auth.getAuthStatus({apiBaseUrl: 'https://id.hey.gg'})
    const Auth = {
        getAuthStatus: function(): Promise<any> {
            const apiBaseUrl = window.HeyGoodGame.config.apiBaseUrl;

            return fetch(`${apiBaseUrl}/auth-or-anon`, {
                method: 'GET',
                credentials: 'include'
            })
            .then(response => response.json())
            .then(data => {
                if (data.status === 'authenticated') {
                    localStorage.setItem('heygg_jwt', data.jwt);
                    localStorage.setItem('heygg_user', JSON.stringify(data.user));
                    localStorage.removeItem('heygg_anonymous_id');
                } else if (data.status === 'anonymous') {
                    localStorage.setItem('heygg_anonymous_id', data.anonymous_id);
                    localStorage.removeItem('heygg_jwt');
                    localStorage.removeItem('heygg_user');
                }
                return data;
            })
            .catch(error => {
                console.error('Error fetching auth status:', error);
                throw error;
            });
        },

        // getToken: function() {
        //     return localStorage.getItem('heygg_jwt') || localStorage.getItem('heygg_anonymous_id');
        // },

        isAuthenticated: function() {
            return !!localStorage.getItem('heygg_jwt');
        },

        // logout: function(callback: (error?: any) => void) {
        //     fetch('https://id.hey.gg/api/logout', {
        //         method: 'POST',
        //         credentials: 'include'
        //     })
        //     .then(() => {
        //         localStorage.removeItem('heygg_jwt');
        //         callback();
        //     })
        //     .catch(error => {
        //         console.error('Error logging out:', error);
        //         callback(error);
        //     });
        // }
    };

    export default Auth;