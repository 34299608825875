export const ACCOUUNT_MESSAGE_HTML = `
<div class="account-message">
  <img src="" alt="" id="account-logo" />
  <h4 id="game-title"></h4>
  <p>
    Save your game stats, view leaderboards, early game updates, see your
    streaks, and join our community.
  </p>
  <a href="" id="free-account-link">
    Get Your Free Account
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons Navigation">
        <path id="Vector 108" d="M6 12.5H18M18 12.5L12.1463 6.5M18 12.5L12.1463 18.5" stroke="black" stroke-width="2"
          stroke-linecap="round" stroke-linejoin="round" />
      </g>
    </svg>
  </a>
</div>
<nav class="sidebar-menu mobile-only">
  <a class="menu-item" href="" id="stats-link">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12805_2551)">
        <path d="M2 12H6V23H2V12Z" fill="#FCFAFA" />
        <path d="M8 1H17V5H13V23H8V1Z" fill="var(--heygg-primary-color)" />
        <path d="M15 7H22V23H15V7Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_12805_2551">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
    Stats
  </a>
  <a class="menu-item" href="" id="settings-link">
    <!-- SVG for settings -->
  </a>
  <a class="menu-item" href="" id="how-to-play-link">
    <!-- SVG for how-to-play -->
  </a>
</nav>
`;
