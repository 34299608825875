const AUTH_CHECK_STORAGE_KEY = 'heygg_last_auth_check';
const API_BASE_URL = 'https://play.hey.gg';

function getQueryParam(param: string) {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(param);
}

function setLocalStorage(key: string, value: number | string | null) {
  if (value === null) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(
      key,
      typeof value !== 'string' ? JSON.stringify(value) : value,
    );
  }
}

function getLocalStorage(key: string) {
  const value = localStorage.getItem(key);
  return value ? (typeof value === 'string' ? value : JSON.parse(value)) : null;
}

async function checkAuth() {
  const now = Date.now();
  const initialToken = getQueryParam('token');
  const initialAnonymousId = getQueryParam('anonymous_id');

  if (initialToken || initialAnonymousId) {
    const cleanSearchParams = new URLSearchParams(window.location.search);
    cleanSearchParams.delete('token');
    cleanSearchParams.delete('anonymous_id');
    const newUrl = `${window.location.pathname}${
      cleanSearchParams.toString() ? `?${cleanSearchParams.toString()}` : ''
    }${window.location.hash}`;
    window.history.replaceState({}, '', newUrl);
  }

  if (initialToken) {
    setLocalStorage('heygg_jwt', initialToken);
    try {
      const response = await fetch(`${API_BASE_URL}/api/user`, {
        headers: {
          Authorization: `Bearer ${initialToken}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to fetch user details');
      const userData = await response.json();
      window.eh2 = userData.email_eh2;
      window.ehttd = userData.email_ehttd;
      setLocalStorage('heygg_user', userData);
      setLocalStorage('heygg_anonymous_id', null);
    } catch (err: any) {
      console.error(err.message || 'Failed to fetch user data');
    }
    setLocalStorage(AUTH_CHECK_STORAGE_KEY, now);
    return;
  }

  if (initialAnonymousId) {
    setLocalStorage('heygg_anonymous_id', initialAnonymousId);
    setLocalStorage('heygg_jwt', null);
    setLocalStorage('heygg_user', null);
    window.eh2 = '';
    window.ehttd = '';
    setLocalStorage(AUTH_CHECK_STORAGE_KEY, now);
    return;
  }

  const existingJwt = getLocalStorage('heygg_jwt');
  const existingAnonymousId = getLocalStorage('heygg_anonymous_id');

  if (!existingJwt && !existingAnonymousId) {
    window.location.href = `${API_BASE_URL}/check-auth?redirect_uri=${encodeURIComponent(
      window.location.href,
    )}`;
    return;
  }

  if (existingJwt) {
    try {
      const response = await fetch(`${API_BASE_URL}/api/user`, {
        headers: {
          Authorization: `Bearer ${existingJwt}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const userData = await response.json();
        window.eh2 = userData.email_eh2;
        window.ehttd = userData.email_ehttd;
        setLocalStorage('heygg_user', userData);
        setLocalStorage('heygg_anonymous_id', null);
        setLocalStorage(AUTH_CHECK_STORAGE_KEY, now);
        return;
      }
      setLocalStorage('heygg_jwt', null);
      setLocalStorage('heygg_user', null);
      window.eh2 = '';
      window.ehttd = '';
    } catch (err) {
      console.warn('Failed to validate existing token:', err);
    }
  }

  if (existingAnonymousId) {
    setLocalStorage(AUTH_CHECK_STORAGE_KEY, now);
    return;
  }

  window.location.href = `${API_BASE_URL}/check-auth?redirect_uri=${encodeURIComponent(
    window.location.href,
  )}`;
}

export default checkAuth;
