const Streaks = {
  getLogs: async (gameKey: string, option: string, since: string) => {
    const apiBaseUrl = window.HeyGoodGame.config.apiBaseUrl;

    const accessToken = localStorage.getItem('heygg_jwt');
    fetch(
      `${apiBaseUrl}/api/stats-log?game_key=${gameKey}&option=${option}&since=${since}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    )
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error fetching streak's logs:", error);
        throw error;
      });
  },
};

export default Streaks;
