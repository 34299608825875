import { Streak } from '../hooks/useStreak';

const Streaks = {
  getLogs: async (
    gameKey: string,
    option: string,
    since: string,
  ): Promise<string[] | undefined> => {
    const apiBaseUrl = window.HeyGoodGame.config.apiBaseUrl;

    const accessToken = localStorage.getItem('heygg_jwt');

    if (!accessToken) {
      return;
    }

    return fetch(
      `${apiBaseUrl}/api/stats-log?game_key=${gameKey}&option=${option}&since=${since}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    )
      .then((response) => response.json())
      .then((data) => data.dates)
      .catch((error) => {
        console.error("Error fetching streak's logs:", error);
      });
  },
  getStreaks: async (
    gameKey: string,
    level: string,
  ): Promise<Streak[][] | undefined> => {
    const apiBaseUrl = window.HeyGoodGame.config.apiBaseUrl;
    const accessToken = localStorage.getItem('heygg_jwt');

    const headers: { 'Content-Type': string; Authorization?: string } = {
      'Content-Type': 'application/json',
    };

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    return fetch(`${apiBaseUrl}/api/streaks?game_key=${gameKey}&level=${level}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error('Error fetching streaks:', error);
      });
  },
};

export default Streaks;
